<template>
    <div id="pageTable">        
        <v-container xl fluid>
            <v-row>
                <v-overlay :value="overlay"  :z-index="999" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                    
                </v-overlay>
                <v-col cols="12" lg="12">
                    <v-tabs v-model="tab" background-color="transparent" gcol >
                        <v-tab v-for="item in items" :key="item" class="pa-0 " >
                            <span class="tabText">{{ item }}</span>
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab" class="mb-10">
                        <v-tab-item :key="0" style="background:#EFEFEF;">
                            <v-col cols="12" xs="12" sm="12" md="12" class="">
                                <v-card class="borde-card big elevation-0">
                                    <v-card-text class="pa-5" >
                                        <p class="p-title mb-10">Consulta tu saldo STP</p>
                                        <ValidationObserver ref="form" v-slot="{ invalid, validated  }">
                                            <v-row>
                                                <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                    <ValidationProvider v-slot="{ errors }" name="RegistroPatronal" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Registro Patronal"
                                                            :items="registroPatronal"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="registro_patronal_value_stp"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors"
                                                            id="txtRegistroPatronalStp"
                                                            ref="autoRegistroPatronal"
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                                    <v-text-field 
                                                        outlined
                                                        label="Saldo actual" 
                                                        class="pa-0 ma-0" 
                                                        v-model="registro_patronal_value" 
                                                        id="txtNombre"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" >
                                                    <div class="botonAgregar">
                                                        <v-btn 
                                                            class="btnDashboard elevation-0 float-right px-6"
                                                            right
                                                            rounded 
                                                            :disabled="invalid" 
                                                        >
                                                            Consultar
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </ValidationObserver>
                                        
                                    </v-card-text>
                                </v-card>
                                <v-divider class="divider"></v-divider>
                                <v-card class="borde-card big elevation-0">
                                    <v-card-text class="pa-5" >
                                        <p class="p-title mb-10">Pagar</p>
                                        <ValidationObserver ref="form" v-slot="{ invalid, validated  }">
                                            <v-row>
                                                <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Cliente"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" sm="12" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="empresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Empresa"
                                                            persistent-hint
                                                            v-model="empresa_value"
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                                    <ValidationProvider v-slot="{ errors }" name="RegistroPatronal" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Tipo de nómina"
                                                            :items="itemsTiposNominas"
                                                            item-text="claveDescripcion"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="tipo_nomina_id"
                                                            class="pa-0 ma-0"
                                                            id="tipoNominaID"
                                                            required 
                                                            :error-messages="errors" 
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="2" md="2" class="pt-0 pb-0 ma-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Periodo" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Periodo"
                                                            :items="itemsPeriodos"
                                                            item-text="numero"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="periodo_value"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors"
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="2" md="2" class="pt-0 pb-0 ma-0">
                                                    <VuetifyDatePicker
                                                        outlined
                                                        disabled
                                                        rules="required"
                                                        v-model="nomina.fecha_inicial"
                                                        label="Fecha inicial"
                                                        placeholder="dd/mm/aaaa"
                                                        ref="FechaInicio"
                                                    />
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="2" md="2" class="pt-0 pb-0 ma-0">
                                                    <VuetifyDatePicker
                                                        outlined
                                                        disabled
                                                        rules="required"
                                                        v-model="nomina.fecha_final"
                                                        label="Fecha final"
                                                        placeholder="dd/mm/aaaa"
                                                        ref="FechaFinal"
                                                    />
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="2" md="2" class="pt-0 pb-0 ma-0">
                                                    <VuetifyDatePicker
                                                        outlined
                                                        disabled
                                                        rules="required"
                                                        v-model="nomina.fecha_de_pago"
                                                        label="Fecha de pago"
                                                        placeholder="dd/mm/aaaa"
                                                        ref="FechaPago"
                                                    />
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                                    <VuetifyMoneyValidate
                                                        rules="required"
                                                        outlined
                                                        disabled
                                                        label="Monto total"
                                                        class="pa-0 ma-0"
                                                        prefix="$"
                                                        v-model="montoTotal" 
                                                        v-bind:options="options"
                                                    />
                                                </v-col>
                                                
                                                <v-col cols="12" >
                                                    <div class="botonAgregar">
                                                        <v-btn 
                                                            class="btnDashboard elevation-0 float-right px-10"
                                                            right
                                                            rounded 
                                                            @click="pagarNomina()"
                                                            :disabled="invalid"
                                                        >
                                                            Pagar
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </ValidationObserver>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>

            <template >
                <v-dialog v-model="dialogDispersion" scrollable persistent width="700px" class="borde-card">
                    <v-card class="borde-card">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor" class="pt-0">
                                    <v-divider class="divider"></v-divider>
                                    <v-col xs="12" sm="12" md="12" class="pa-0 ma-0 mt-3 radio_group_01 radio-contrato" >
                                        <v-radio-group v-model="pagoNomina.tipo_pago">
                                            <template v-slot:label>
                                                <div class="mb-4">Selecciona una forma de pago</div>
                                            </template>
                                            <v-radio class="mb-4 radio-label" label="STP" value="1"></v-radio>
                                            <v-radio class="mb-4 radio-label" label="TXT" value="2"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col v-if="pagoNomina.tipo_pago == '2'" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <template v-for="(item,key) in itemsRegistrosPatronalesCuentas">
                                            <v-col class="px-0 ma-0" cols="12" :key="key">
                                                Registro Patronal - {{item.nombre}}
                                            </v-col>

                                            <template v-if="item.cuentas.length > 0">
                                                <v-row class="px-0 ma-0" cols="12" v-for="(cuenta,key2) in item.cuentas" :key="key2">
                                                    <v-col cols="12" xs="12" sm="12" md="6">{{cuenta.banco.nombre}} / No. CTA {{cuenta.cuenta_bancaria}}</v-col>
                                                    <v-col cols="12" xs="12" sm="12" md="6" >
                                                        <v-btn
                                                            class="btnGenerar ml-2 elevation-0 float-right"
                                                            @click="descargarTXT(item.id,cuenta.banco_id)" 
                                                        >
                                                            Generar TXT
                                                        </v-btn>
                                                        <v-btn
                                                            fab
                                                            small
                                                            class="btnVisualizar elevation-0 float-right"
                                                            @click="visualizarTXT(item.id,cuenta.banco_id)" 
                                                        >
                                                            <v-icon class="outlined_v_icon">visibility</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template v-else>
                                                <v-row class="text-center" :key="key">
                                                    <v-col class="px-0 ma-0" cols="12">
                                                        No cuentas con bancos relacionados al registro patronal
                                                    </v-col>
                                                    <v-col>
                                                        <v-btn
                                                            class="btnGenerar mr-2"
                                                            @click="$router.push({ path: 'registroPatronal' })" 
                                                            >
                                                            Agregar banco
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </template>
                                    </v-col>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cerrar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                   @click="dispersarNomina()" 
                                   :loading="isSaving"
                                   :disabled="pagoNomina.tipo_pago == '2'"
                                >
                                    Dispersar
                                </v-btn>
                            </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogVisualizacionTxt" persistent max-width="700px" scrollable class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalVisualizacionTxt"></v-btn>
                    </template>
                        <v-card class="borde-card">
                            <perfect-scrollbar>
                                <v-card-text >
                                    <div id="padre" v-show="isLoadingVisualizacionTxt">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingVisualizacionTxt" grid-list-md id="contenedor" class>
                                        <v-row class="pa-0 ma-0"> 
                                            <v-row class="pa-0 mx-0 my-9 informacionGeneral">
                                                <v-row class="px-9 pt-12 pb-7">
                                                    <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                        <p class="informacionGeneralTitulo">Información general</p>
                                                    </v-col> 
                                                    <v-col cols="12" xs="12" sm="12" md="5" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Cuenta emisora del R..P:</p>
                                                        <p class="informacionGeneralText">{{dataGeneral.cuenta_emisora}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="12" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Importe total:</p>
                                                        <p class="informacionGeneralText">$ {{dataGeneral.total_importe}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="12" md="3" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de registros:</p>
                                                        <p class="informacionGeneralText">{{dataGeneral.total_registros}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="12" md="5" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Cuenta ordenante del R..P:</p>
                                                        <p class="informacionGeneralText">{{dataGeneral.cuenta_ordenante}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="12" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Fecha de proceso:</p>
                                                        <p class="informacionGeneralText">{{dateFormat(dataGeneral.fecha_proceso, 'modal')}}</p>
                                                    </v-col>
                                                </v-row>
                                            </v-row>
                                                
                                            <simple-table
                                                class="tablaWidth"
                                                :rows="itemsEmpleadosTxt"
                                                :columns="headersTableEmpleados"
                                                :perPage="[10, 25, 50, 100]"
                                                shortPagination
                                                :modalTable="true"
                                            >
                                                <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                                                    <tr :key="item.id_empleado" v-for="(item, index) in data">
                                                        <td class="pa-0">
                                                            <expandable-item-2 :image="urlImg + item.foto_empleado" :title="item.nombre_empleado">
                                                                <template slot="content">
                                                                    <table class="tbl-child" width="100%">
                                                                        <tbody>
                                                                            <template>
                                                                                <tr>
                                                                                    <td class="pt-4"><p class="subtitle">Clave del empleado: </p></td>
                                                                                    <td class="pt-4"><p class="text">{{item.clave_empleado}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Neto a pagar: </p></td>
                                                                                    <td><p class="text">$ {{item.importe}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Banco: </p></td>
                                                                                    <td><p class="text">{{item.banco}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Clabe bancaria: </p></td>
                                                                                    <td><p class="text">{{item.clabe_bancaria}}</p></td>
                                                                                </tr>
                                                                            </template>
                                                                        </tbody>
                                                                    </table>
                                                                </template>
                                                            </expandable-item-2>

                                                            <hr style="border: 1.5px solid #F2F2F2;" v-if="index < data.length - 1" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </simple-table>
                                        </v-row>  
                                    </v-container>
                                </v-card-text>
                            </perfect-scrollbar>
                            <v-card-actions>
                                <v-layout row>
                                    <v-flex class="justify-center">
                                        <div class="btnModal">
                                            <button class="btnCerrar modal-pull-right" @click="cerrarModalVizualizacion()">Cerrar</button>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card-actions>
                        </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
//APIS
import Vue from 'vue';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiPeriodos from '@/api/nominas/periodos';
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import pagosApi from '@/api/nominas/pagos';
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import apiTesoreria from '@/api/nominas/tesoreria';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';

//COMPONENTS
import Datatable from '@/components/datatable/Datatable';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import ExpandableItem2 from '@/components/ExpandableItem.vue';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ExpandableItem from '@/components/ExpandableItemPayments.vue';

//PLUGINS
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    components: {
        VuetifyDatePicker,
        'data-table' : Datatable,
        ExpandableItem,
        "expandable-item-2" : ExpandableItem2,
        "simple-table": DatatableSimple,
        VuetifyMoneyValidate,
    },
    data() {
        return {
            registro_patronal_value     : null,
            registro_patronal_value_stp : null,
            tab                         : 0,
            items                       : ['Pagar'], //['Pagar', 'Control de pagos'],
            datosLogin                  : null,
            rol                         : null,
            cliente_value               : null,
            empresa_value               : null,
            tipo_nomina_id              : null,
            periodo_value               : null,

            dialogVisualizacionTxt      : false, 
            isLoadingVisualizacionTxt   : false,
            dialogDispersion            : false,
            isSaving                    : false,
            overlay                     : false,
            fraseOverlay                : "Descargando",
            titulo_modal                : "",
            imagen                      : "/static/modal/contrato.png",
            

            itemsEmpleadosTxt               : [],
            clientes                        : [],
            empresas                        : [],
            sucursales                      : [],
            registroPatronal                : [],
            itemsTiposNominas               : [],
            itemsPeriodos                   : [],
            itemsRegistrosPatronalesCuentas : [],
            headersTableEmpleados   : [
                { 
                    label       : "Empleados", 
                    align       :'center',   
                    name        : "nombre_completo",    
                    filterable  : false 
                },
            ],
            pagoNomina      : {
                tipo_pago : "1"
            },
            dataGeneral     : {
                cuenta_emisora      : "",
                total_importe       : "",
                total_registros     : "",
                cuenta_ordenante    : "",
                fecha_proceso       : ""
            },
            nomina           : {
                id : null,
                fecha_inicial : null,
                fecha_final : null , 
                fecha_de_pago : null,
                total : null,
            },
            montoTotal:null,
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
        }
    },
    /* WATCH 
    Entrada: valor nuevo de la variable y valor pasado de la variable (newValue,oldValue)
    Salida:
    Descripcion: Las funciones de este tipo reciben el valor 
                 nuevo y pasado de una variable cuando detecta 
                 un cambio para posteriormente ejecutar alguna 
                 instruccion.*/
    watch: {
        /*Descripcion: Ejectuta cambiaCliente() mandando
                       el nuevo valor de esta variable*/
        cliente_value: function(val) {
            this.cambiaCliente(val);
            this.getRegistroPatronal();
        },
        /*Descripcion: Ejectuta cambiaEmpresa() mandando
                       el nuevo valor de esta variable*/
        empresa_value: function(val) {  
            if(val!= null){
                this.getTiposNominas();
            }
        },
        tipo_nomina_id: async function(val, oldVal){
            if(val != null){
                this.periodo_value = null;
                this.nomina.fecha_de_pago = null;
                this.nomina.fecha_inicial = null;
                this.nomina.fecha_final = null;
                this.nomina.total = null;
                this.montoTotal  = null;

                this.$nextTick(()=>{
                        this.$refs.FechaInicio.setDate(null);
                        this.$refs.FechaFinal.setDate(null);
                        this.$refs.FechaPago.setDate(null);
                    });

                await this.getPeriodos(val);
            } 
        },

        periodo_value: async function(val, oldVal){
            if(val != null){
                await this.getNomina();
                this.getMonto();
            } 
        },
    },
    
    methods: {
        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte una fecha de formato AAAA-MM-DD => DD/MM/AAAA. Se usa para el modal y la tabla 
         * @param {string} fecha formato AAAA-MM-DD
         * @param {string} tipo string con valor 'modal' o 'tabla'
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha, tipo) {
            if(fecha == null){
                return;
            }
            else{
                let fecha2 = fecha.split("-");
                if(tipo == 'modal'){
                    return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
                }else{
                    return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
                }
            }
            
        },
        async descargarTXT( registro_patronal_id, banco_id ){
            let params = { 
                nomina_id           : this.nomina.id, 
                registro_patronal_id: registro_patronal_id,
                banco_id            : banco_id,
                previsualizar       : false 
            };
            let self = this;
            const FileDownload = require("js-file-download");
            let url = "pagos/generar-layout";
            let nombre_archivo = "formato_pago.";
            self.overlay = true;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    content_type:"aplication/json",
                    headers: {
                        "content-type": "application/json",
                    },
                    data: params,
                }).then(function(response) {
                    let extensionArchivo = response.headers["content-type"].split("||");
                    FileDownload(response.data, nombre_archivo+extensionArchivo[1]);
                    self.overlay = false;
                }).catch((error)=> {
                    self.overlay = false;
                    console.log(decodeURIComponent(escape(error.response.headers["content-type"])));
                    Notify.ErrorToast(decodeURIComponent(escape(error.response.headers["content-type"])));

                });
            } catch (err) {
                Notify.ErrorToast("Algo ha salido mal, intenta de nuevo o consulta a soporte técnico");
            }
        },

        async visualizarTXT( registro_patronal_id, banco_id ){
            let params = { 
                nomina_id           : this.nomina.id, 
                registro_patronal_id: registro_patronal_id,
                banco_id            : banco_id,
                previsualizar       : true 
            };
            let self = this;
            let url = "pagos/generar-layout";
            self.isLoadingVisualizacionTxt = true;
            
            await Vue.axios({
                method: "POST",
                url: url,
                content_type:"aplication/json",
                data: params,
            }).then(function(response) {
                self.dataGeneral = response.data.generales;
                self.itemsEmpleadosTxt = response.data.empleados;
                self.dialogVisualizacionTxt = true;
                self.isLoadingVisualizacionTxt = false;
            }).catch((error)=> {
                self.isLoadingVisualizacionTxt = false;
                console.log(decodeURIComponent(escape(error.response.headers["content-type"])));
                Notify.ErrorToast(decodeURIComponent(escape(error.response.headers["content-type"])));
            });
            
        },

        async pagarNomina(){
            this.titulo_modal = "Forma de pago";
            this.dialogDispersion = true;
            let params = { 
                nomina_id: this.nomina.id, 
                paginate: false 
            };
            await pagosApi.pagarNomina(params).then(response => {
                this.itemsRegistrosPatronalesCuentas = response;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar la nómina");
            });
        },

        //FUNCIONES GENERALES

        /*
        Entrada:
        Salida:
        Descripción: Se ejectua desde el crate para poder ver 
                     el tipo del rol del usuario loguado y en base a eso 
                     realizar validaciones por rol ademas de ejecutar las 
                     funcinones para el funcionamiento inicial del modulo.
        */
        init() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            
            this.urlImg = GetUrlImg.getURL();

            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                Notify.ErrorToast("Tu rol no tiene acceso a este módulo");
            }

        },

        async getRegistroPatronal(){
            let parametros = { 
                activo      : true, 
                paginate    : false, 
                order       : "asc", 
                cliente_id  : this.cliente_value 
            }
            await apiRegistroPatronal.find(parametros).then((response) => {
                this.registroPatronal = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },

        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value
            }
            this.itemsTiposNominas = [];
            await apiTipoNomina.find(param).then((response) => {
                let format = response.data;
                if(format.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.itemsTiposNominas.push(item);
                    })
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },
        
        async getPeriodos(tipoNomID){
            let param = {
                activo      : true,
                paginate    : false,
                tipo_nomina_id : tipoNomID,
                acumulado   : true,
                cerrado     : true,
            }
            await apiPeriodos.find(param).then((response) => {
                //filtrar por acumulados y cerrados
                let itemsPeriodos = response.data.filter(item => (item.acumulado == true && item.cerrado == true));
                this.itemsPeriodos = itemsPeriodos;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los periodos");
            })
        },

        async getNomina(){
            let param = {
                acumulado : true,
                paginate    : false,
                periodo_id : this.periodo_value,
                tipo_nomina_id: this.tipo_nomina_id
            }
            await apiCalculoNomina.find(param).then((response) => {
                if(response.data[0] != undefined){
                    this.nomina = response.data[0];
                    let periodo = this.itemsPeriodos.find(item => item.id == this.nomina.periodo_id);

                    this.nomina.fecha_de_pago = periodo.fecha_de_pago;
                    this.nomina.fecha_inicial = periodo.fecha_inicial;
                    this.nomina.fecha_final = periodo.fecha_final;

                    
                        this.$refs.FechaInicio.setDate(this.nomina.fecha_inicial);
                        this.$refs.FechaFinal.setDate(this.nomina.fecha_final);
                        this.$refs.FechaPago.setDate(this.nomina.fecha_de_pago);
                }else{
                    this.periodo_value = null;
                    this.nomina.fecha_de_pago = null;
                    this.nomina.fecha_inicial = null;
                    this.nomina.fecha_final = null;

                    this.$nextTick(()=>{
                        this.$refs.FechaInicio.setDate(null);
                        this.$refs.FechaFinal.setDate(null);
                        this.$refs.FechaPago.setDate(null);
                    });
                    Notify.ErrorToast("No se pudo encontrar la nómina a pagar.");
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudo encontrar la nómina a pagar.");
            })            
        },

        getMonto(){
            this.montoTotal = null;
            if(this.periodo_value == null){
                return;
            }

            let parametros = { arrayPeriodo : [this.periodo_value]}
            apiTesoreria.getMonto(parametros).then((response) => {
                this.montoTotal = response;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudo encontrar la nómina a pagar.");
            })
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de clientes para llenar 
                     el select en el filtrado.
        */
        async getClientes() {
            let param = { activo: true, paginate: false };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Empresas para llenar 
                     el select en el filtrado.
        */
        async cambiaCliente(cliente) {
            if(cliente == null){
                return;
            }

            let param = { activo: true, paginate: false, cliente_id: cliente };
            await empresaApi.getEmpresas(param).then(response => {
                this.empresas = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

         /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Sucursales para llenar
                     el select en el filtrado.
        */
        async cambiaEmpresa(empresa) {
            if(empresa == null){
                return;
            }
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            await sucursalApi.getSucursales(parametros).then(response => {
                this.sucursales = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },

        /*
        Entrada:err Array de string
        Salida:
        Descripción: Recibe un array de errores de los apis para 
                     ser mostrados con un toast.
        */
        respuesta_error(err) {
            try {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            } catch (error) {
                Notify.ErrorToast("error");
            }
        },

        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables para cerrar un 
                     modal y dejarlo en estado inicial.
        */
        cerrarModal() {
            this.isSaving = false;
            this.dialogDispersion = false;

            this.resetValues();
        },

        cerrarModalVizualizacion() {
            this.dialogVisualizacionTxt = false;
        },

        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables del modulo 
                     segun el rol para dejarlo en estado inicial.
        */
        resetValues() {

            this.$nextTick(() => { 
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }

                if (this.rol == "root") {
                    this.cliente_value = null;
                    this.empresa_value = null;
                    this.empresas = [];
                    this.sucursales = [];
                }
    
                if (this.rol == "admin") {
                    this.empresa_value = null;
                    this.sucursales = [];
                }

                this.itemsPeriodos          = [];
                this.itemsTiposNominas      = [];
                this.montoTotal             = null;
                this.nomina.fecha_de_pago   = null;
                this.nomina.fecha_final     = null;
                this.nomina.fecha_inicial   = null;
                this.nomina.total           = null;
                this.periodo_value          = null;
                this.tipo_nomina_id         = null;


                if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                    this.$refs.FechaInicio.resetDate();
                }
                if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                    this.$refs.FechaFinal.resetDate();
                }
                if(this.$refs.FechaPago != undefined || this.$refs.FechaPago != null){
                    this.$refs.FechaPago.resetDate();
                }
            });

        },

    },
    //CREATED Se ejecutan las funciones o intrucciones iniciales para el funcionamiento del modulo.
    created() {
        this.init();
    },
    
}
</script>


<style scoped>
    #padre {
        padding-left: 40%;
        height: 200px;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -15px;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .radio_group_01 div{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }

    .btnDashboard.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        text-transform: capitalize;
        transition: 0.3s;
        height: 38px !important;
        background: #1E2245;
        border-radius: 50px;
    }

    .btnDashboard.theme--light.v-btn:hover {
        background: #1E2245 !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
        -moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
    }

    .p-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }

    .btnGenerar {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        background-color: #2DB9D1 !important;
        border-radius: 80px !important;
        color: #FFFFFF !important;
        outline: none;
        text-transform: none !important;
        transition: all .2s;
        padding: 10px 23px;
    }

    .btnVisualizar {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        background-color: #EFEFEF !important;
        border-radius: 150px !important;
        color: #A7A7A7 !important;
        outline: none;
        text-transform: none !important;
        transition: .3s;
        padding: 10px;
    }

    .btnVisualizar:hover {
        background-color: #FFF !important;
        color: #2DB9D1 !important;
        margin-top: -2px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
    }


    .radio_group_01{
        margin-right: 48px;
        margin-left: 5px;
    }
    .tablaWidth{
        width: 100%;
        width: -moz-available;          
        width: -webkit-fill-available;  
        width: fill-available;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
        padding-left: 15vh;
    }
    #tableScrollSimple tbody{
        max-height:none !important;
        min-width: 100% !important;
        background-color: #fff !important;
    }
    .tbl-child tbody td:first-child {
        padding-left: 35px !important;
        width: 320px;
    }
    .informacionGeneral{
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
</style>
