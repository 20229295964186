<template>
    <div class="row-container">
        <template v-if="!titleSlot">
            <div class="parent-info divBadge " :class="{'active': isOpen}">
                <div class="title-container">
                    <img v-if="image" :src="image" class="image-profile" />
                    <span style="min-width: 280px;" class="d-flex">{{ title }} <p v-if="numero" class="pl-7" style="color:#1E2144; font-weight: 600;">Total empleados:  {{numero }}</p></span> 
                    <v-btn 
                    v-if="id!=null && type == 'FINIQUITO' && valid"
                    color="#1E2245" 
                    class="btnContrats elevation-0 mx-10"
                    right
                    rounded 
                    @click="descargar()">
                        Descargar Carta
                    </v-btn>
                    <div v-if="!valid" class="mx-10">
                        <span class="badge">Revisar</span>
                    </div>
                </div>
                
                <i v-if="isOpen" class="material-icons pr-4" @click="isOpen = false">keyboard_arrow_up</i>
                <i v-else class="material-icons pr-4" :class="{'mt-icon' : image}" @click="isOpen = true">keyboard_arrow_down</i>
            </div>
        </template>

        <template v-else>
            <slot name="title" v-if="titleSlot"></slot>
        </template>

        <transition name="slide-fade">
            <div class="child-info" v-if="isOpen">
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'expandable-item',
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        id: {
            type: Number,
            required: false,
            default: null
        },
        image: {
            type: String,
            required: false,
            default: ''
        },
        numero: {
            type: [String,Number],
            required: false,
            default: ''
        },
        valid:{
            type: Boolean,
            required: false,
            default: true
        },
        type:{
            type: String,
            required:false,
            default:''
        }
    },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        titleSlot() {
            if(this.$scopedSlots) {
                return this.$scopedSlots.title;
            }

            return null;
        },
    },
    methods: {
        descargar() {
            this.$emit('descargar',[{empleado_id:this.id}]);
        }
    },
}
</script>

<style scoped>
    .child-info{
        width: 100%;
        background-color: #FFFFFF;
    }
    .row-container {
        display: initial;
    }

    .row-container .parent-info {
        display: flow-root;
        padding: 10px 0px;
        background-color:transparent;
    }

    .row-container .parent-info.active {
        background-color: #F9F9F9 !important;
    }

    div.title-container { 
        display: inline-flex;
        align-items: center;

        padding-left: 35px !important;
    }

    .image-profile {
        height: 40px;
        width: 40px;
        border-radius: 50%;

        margin-right: 15px;
    }

    .row-container .parent-info div span {
        float: left;

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #101C5A;
    }

    .row-container .parent-info i {
        float: right;

        color: #101C5A;
        cursor: pointer;
    }

    .row-container .parent-info i.mt-icon {
        margin-top: 6px;
    }

    /**
    * TRANSITION EFFECT
    */
    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition-duration: 0.8s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .slide-fade-enter,
    .slide-fade-leave-active {
    opacity: 0
    }

    .badge{

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 115.9%;
        color: #FFFFFF !important;
        background-color: #D93025;
        padding:7px 15px;
        border-radius: 50px;

    }
    .divBadge{
        align-content: center !important;
    }

    .btnContrats.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        height: 26px;
        color: #FFFFFF;
        text-transform: capitalize;
    }

</style>