import Vue from 'vue';

export default {
    /* 
        API anterior
        getMonto(periodo_id) {
            return Vue.axios.get("calculo-nomina/monto-pagar", { params: periodo_id }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
        }, 
    */
    getMonto(parametros) {
        return Vue.axios.post("calculo-nomina/monto-pagar", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getPeriodosMontos(parametros){
        return Vue.axios.post("dispersion/find-periodos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    dispersar(parametros) {
        return Vue.axios.post("dispersion", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error))
    }
}